.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-display {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}
.cursor-pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: none !important;
}

.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}
.width-25 {
  width: 25%;
}

.height-100 {
  height: 100%;
}
.margin-auto {
  margin: auto;
}

.border {
  border: 1px solid #080808;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.text-underline {
  text-decoration: underline !important;
}

.text-none {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.lowercase {
  text-transform: lowercase !important;
}

.text-align-center {
  text-align: center;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}
.border-1px-solid {
  border: 1px solid red;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-focus-animate {
  box-shadow: inset 0 0 0 0 #31302b !important;
  -webkit-transition: all ease 1s !important;
  -moz-transition: all ease 1s !important;
  transition: all ease 1s !important;
}

.btn-focus-animate:focus {
  box-shadow: inset 200px 0 0 0 #000 !important;
  color: #fff !important;
}
