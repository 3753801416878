.MuiDateTimePickerToolbar-separator {
  margin: auto !important;
}

.css-r6omig-MuiButtonBase-root-MuiTab-root {
  font-size: 24px !important;
}

/* This is the React cron library styling. */
/* .react-js-cron-select.react-js-cron-custom-select.ant-select,
.react-js-cron-select.react-js-cron-custom-select.ant-select-single,
.react-js-cron-select.react-js-cron-custom-select.ant-select:not(.ant-select-disabled):hover {
  width: 50px;
} */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  border: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}
